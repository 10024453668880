<template>
  <vx-card :title="title">
    <!-- <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      >Create</vs-button
    > -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-12 mt-3">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              name="territory"
              :limit="5"
              :disabled="false"
              @input="onselectedTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :loading="isLoadingCus"
        :internal-search="false"
              :max-height="200"
              :limit="5"
              :disabled="false"
              placeholder="Type to search"
              @input="onSelectedCus"
              @search-change="handlerSearchCustomer"
              :custom-label="customLableCustomer"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Bill To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedBillTo"
              :options="optionBillTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
        :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="false"
              placeholder="Type to search"
              :custom-label="customLableBillto"
            />
            <span
            class="text-danger text-sm"
            v-show="isErrorBillTo"
            >{{ msgErrorBillto }}</span>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Shipp To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              name="billto"
              v-model="selectedShippTo"
              :options="optionShippTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
        :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="false"
              v-validate="'required'"
              placeholder="Type to search"
              :custom-label="customLableShippTo"
            />
            <span
            class="text-danger text-sm"
            v-show="true"
            >{{ msgErrorShippTo }}</span>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="date"
              :inline="false"
              v-model="dateCo"
              placeholder="Select Date"
              v-validate="'required'"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Request Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              :inline="false"
              v-model="rDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Due Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="duedate"
              :inline="false"
              v-model="duedate"
              placeholder="Select Date"
              v-validate="'required'"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-textarea
        v-model="note"
        :disabled="false"
      />
          </div>
        </div>

        <div style="margin-top:50px">
          <div class="vx-row mb-6" style="width: 100%">
            <div :class="'vx-col w-full '+(indextr==0?'sm:w-3/12':'sm:w-2/12')" style="font-weight: bold;" :key="indextr" v-for="(tr, indextr) in table.data[0].formInputs">
              <span>{{ tr.head }}</span>
            </div>
            <div class="vx-col sm:w-1/12 w-full">
              <span></span>
            </div>
          </div>
          <div class="vx-row mb-6" style="width: 100%" :key="index" v-for="(dt, index) in table.data">
            <div :class="'vx-col w-full '+(index2==0?'sm:w-3/12':'sm:w-2/12')" :key="index2" v-for="(dt2, index2) in dt.formInputs">
              <FormInput
                      :components="dt2" @onSelected="onselected" @search-change="handlerSearchSelect"
                      /> 
            </div>
            <div class="vx-col sm:w-1/12 w-full">
              <vs-td v-if="index == 0" >
            <vx-tooltip text="Add Item">
              <vs-button
                size="small"
                color="green"
                icon-pack="feather"
                icon="icon-plus"
                @click="handleAddItem()"
              />
            </vx-tooltip>
          </vs-td>
          <vs-td v-else>
            <vx-tooltip text="Delete Item">
              <vs-button
                size="small"
                color="red"
                icon-pack="feather"
                icon="icon-x"
                @click="handleRemoveItem(index)"
              />
            </vx-tooltip>
          </vs-td>
            </div>
          </div>
        </div>
        <vs-button
          class="m-6 float-right"
          color="success"
          type="border"
          @click="handleSubmit()"
          >Submit</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
export default {
  components :{
    Datepicker,
    FormInput
  },

  data() {
    const id = this.$route.params.id;

    return {
      baseUrl: "/api/sfa/v1/customer-order/create",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",
      deleteId: null,
      optionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isLoadingCus:false,
      selectedTerritory: {
        id:null
      },
      selectedCustomer: {
        code:"",
        name:""
      },
      selectedBillTo: {
        id:null,
        CustomerID:"",
        Address:"",
      },
      selectedShippTo: {
        id:null,
        CustomerID:"",
        Address:"",
      },
      searchCust:"",
      searchItem:"",
      table: this.tableDefaultState(),
      dateCo:null,
      duedate:null,
      note:null,
      rDeliveryDate:null,
      title:"Form Customer-order",
      id: id,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [
          {
            id:1,
            formInputs:[
              {
                id:1,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                isLoading:false,
                multiple: false,
                allowempty: false,
                value: {
                  text:"",
                },
                optionValue:[],
              },
              {
                id:2,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "qty",
                placeholder: "QTY",
                head:"QTY",
                type: "input",
                value: "",
              },
              {
                id:3,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "price",
                placeholder: "Price",
                head:"Price",
                type: "input",
                value: "",
              },
              {
                id:4,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "discount",
                placeholder: "Discount",
                head:"Discount",
                type: "input",
                value: "",
              },
              {
                id:5,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "discount",
                placeholder: "Tax",
                head:"Tax",
                type: "input",
                value: "",
              },
            ]
        }
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handlerSearchCustomer(search){
      this.searchCust = search
      this.getDataCustomer(this.selectedTerritory)
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    onselected(select){
      // console.log(select)
    },
    onSelectedCus(select){
      this.optionBillTo =select.customer_address
      this.optionShippTo =select.customer_address

      this.selectedBillTo = this.optionBillTo[0]
      this.selectedShippTo = this.optionShippTo[0]
    },
    
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    customLableBillto({ CustomerID, Address }) {
      return `${CustomerID} - ${Address}`;
    },
    customLableShippTo({ CustomerID, Address }) {
      return `${CustomerID} - ${Address}`;
    },
    onselectedTerritory(Select){
      this.getDataCustomer(Select)
    },
    handleSubmit() {
      console.log(this.selectedShippTo)
      if (this.selectedBillTo !=undefined&&
      this.selectedShippTo!=undefined) {
        if (this.selectedBillTo.id&&
          this.selectedShippTo.id) {
            this.$vs.dialog({
              type: "confirm",
              color: "danger",
              title: `Confirm`,
              text: "Please confirm to submit",
              accept: this.acceptSubmit,
            });
          }
      }
      // console.log(this.selectedBillTo)
      if (this.selectedBillTo) {
        if (!this.selectedBillTo.id) {
          this.isErrorBillTo =true
          this.msgErrorBillto ="bill to is required "+this.selectedBillTo.id
        }else{
          this.isErrorBillTo=false
          this.msgErrorBillto =""
        }
      }else{
          this.isErrorBillTo =true
          this.msgErrorBillto ="bill to is required "
      }
      if (this.selectedShippTo) {
        if (!this.selectedShippTo.id) {
          this.isErrorShippTop =true
          this.msgErrorShippTo ="shipp to is required "+this.selectedShippTo.id
        }else{
          this.isErrorShippTop=false
          this.msgErrorShippTo =""
        }
      }else{
        this.isErrorShippTop =true
          this.msgErrorShippTo ="shipp to is required "
      }
    },   
    acceptSubmit() {
      this.$vs.loading();
      let item =[]
      this.table.data.forEach(dt=>{
        item.push({
            "item_unit_id":dt.formInputs[0].value.id,
            "quantity":dt.formInputs[1].value?parseInt(dt.formInputs[1].value):0,
            "price":dt.formInputs[2].value?parseInt(dt.formInputs[2].value):0,
            "charge":0,
            "discount":dt.formInputs[3].value?parseInt(dt.formInputs[3].value):0,
            "tax":dt.formInputs[4].value?parseInt(dt.formInputs[4].value):0

        })
      })
      const params = {
        "customer_orders":[
            {
                "territory_id":this.selectedTerritory.id,
                "customer_id":this.selectedCustomer.id,
                "billTo_id":this.selectedBillTo.id,
                "shipTo_id":this.selectedShippTo.id,
                "date" :this.dateCo?moment(String(this.dateCo)).format("YYYY-MM-DD"):null,
                "request_delivery_date" :this.rDeliveryDate?moment(String(this.rDeliveryDate)).format("YYYY-MM-DD"):null,
                "due_date":this.duedate?moment(String(this.duedate)).format("YYYY-MM-DD"):null,
                "source":"sfa-web",
                "status":0,
                "note":this.note,
                "Items":item
            }
        ]
      };

      this.$http
        .post(this.baseUrl, params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            let msg ="",title = ""
            console.log(resp.data)
            
            console.log(resp.data[0].status_create )
            if(resp.data[0].status_create){
              let msgArr =[]
              if (resp.data[0].status_create.status=="Error") {
                if (resp.data[0].status_create.messages){
                  msgArr = resp.data[0].status_create.messages
                }
                title = resp.data[0].status_create.State
                msgArr.forEach((dt,i)=>{
                  msg +=dt
                  if ((i+1)<msgArr.length){
                    msg +=", "
                  }
                })
                this.$vs.notify({
                  color: "danger",
                  title: title,
                  text: msg,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }else if (resp.data[0].status_create.status=="Success"){
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully submitted",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
                this.$router.push({ name: "customer-order" });
              }
              // msg = resp.data[0].StatusCreate.Message
            }
              
          } else {
            if (resp.data) {
              if(resp.data.messages){
                resp.data.messages.forEach(dt => {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: dt,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                });
              }
            }else{
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },

    handlerSearchSelect(searching,comp) {
      this.getItem(searching,this.table.data[comp.parentId-1].formInputs)
    },

    handleAddItem() {
      let i_new = this.table.data.length
      this.table.data.push({
            id:i_new+1,
            formInputs:[
              {
                id:1,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
              {
                id:2,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "qty",
                placeholder: "QTY",
                head:"QTY",
                type: "input",
                value: "",
              },
              {
                id:3,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "price",
                placeholder: "Price",
                head:"Price",
                type: "input",
                value: "",
              },
              {
                id:4,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "discount",
                placeholder: "Discount",
                head:"Discount",
                type: "input",
                value: "",
              },
              {
                id:5,
                parentId:i_new +1,
                disabled: false,
                validate: "required",
                name: "discount",
                placeholder: "Tax",
                head:"Tax",
                type: "input",
                value: "",
              },
            ]
        })
        this.getItem(null,this.table.data[i_new].formInputs)
    },
    handleRemoveItem(index) {
      this.table.data = this.table.data.filter((v, i) => {
          return i != index;
        });
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "customer-order" });
    },
    getDataCustomer(SelectTerritory) {
      this.isLoadingCus =true
      this.$http
        .get("/api/sfa/v1/customer", {
          params: {
            length: 40,
            // page: this.table.page,
            search: this.searchCust,
            // order: this.table.order,
            // sort: this.table.sort,
            territory_id: SelectTerritory.id,
          },
        })
        .then((resp) => {
          this.isLoadingCus =false
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = resp.data.records;
              // console.log(this.optionCustomer)
              if (this.optionCustomer.length > 0) {
                if (!this.selectedCustomer.id) {
                  // this.selectedCustomer = this.optionCustomer[0];
                }
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = {};
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
          } else {
            console.log(resp.data);
          }
        });
    },
    getItem(search,comp) {
      let idx =0
      if (comp) {
        idx = comp[0].parentId-1;
        this.table.data[idx].formInputs[0].isLoading =true
      }else{
        this.table.data.forEach((dt,i) => {
          this.table.data[i].formInputs[0].isLoading =true
        });
      }
      let searchItem =""
      if(search){
        searchItem = search
      }
      this.$http
        .get("/api/sfa/v1/customer-order/list-item-unit", {
          params: {
            length: 40,
            search: searchItem,
          },
        })
        .then((resp) => {
          console.log(resp)
          this.table.data[idx].formInputs[0].isLoading =false
          if (resp.code == 200) {
            let data =[]
            resp.data.records.forEach(dt=>{
              data.push(
                {
                  id:dt.item_unit_id,
                  code:dt.code,
                  text:dt.text
                }
              )
            })
            // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",this.table.data)
            if (comp) {
                this.table.data[idx].formInputs[0].optionValue = data
                // if (!this.table.data[idx].formInputs[0].value.id) {
                //   this.table.data[idx].formInputs[0].value =data[0]
                // }
            }else{
              this.table.data.forEach((dt,i) => {
                this.table.data[i].formInputs[0].optionValue =data
                // this.table.data[i].formInputs[0].value =data[0]
                this.table.data[i].formInputs[0].isLoading =false
              });
            }
          } else {
            console.log(resp.data);
          }
        });
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {},
  mounted() {
    // this.dateCo = moment(moment().valueOf());
    // this.duedate = moment(moment().valueOf()).endOf('month')

    this.getTerritory();
    this.getDataCustomer(this.selectedTerritory)
    this.getItem()
    // this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
</style>
